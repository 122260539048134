// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/webpacker and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();

console.log('=== Surfoda started! ===');

import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import Buefy from 'buefy'
import HostWizard from '../src/vue/host-wizard.vue'
import ActivityEdit from '../src/vue/activity-edit.vue'
import ActivityCarousel from '../src/vue/activity-carousel.vue'
import BookIt from '../src/vue/book-it.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMinusCircle, faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle,
    faArrowUp, faAngleRight, faAngleLeft, faAngleDown, faMinus, faPlus,
    faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload, faCalendarCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueAgile from 'vue-agile'

// add icons to library
library.add(faMinusCircle, faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle,
    faArrowUp, faAngleRight, faAngleLeft, faAngleDown, faMinus, faPlus,
    faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload, faCalendarCheck);

Vue.use(TurbolinksAdapter);
Vue.use(VueAgile);
Vue.use(Buefy, {
    defaultIconComponent: 'vue-fontawesome',
    defaultIconPack: 'fas',
});
Vue.component('host-wizard', HostWizard);
Vue.component('activity-edit', ActivityEdit);
Vue.component('activity-carousel', ActivityCarousel);
Vue.component('book-it', BookIt);
Vue.component('vue-fontawesome', FontAwesomeIcon);

document.addEventListener('turbolinks:load', () => {
    new Vue({
        el: '[data-behavior="vue"]'
    });

    if (typeof gtag === 'function') {
        gtag('config', 'UA-136431983-1');
    }
});