<template>
    <div class="activity-carousel">
        <agile ref="carousel" :options="carouselOptions">
            <template v-for="(photo, photoIndex) in photos">
                <img :src="photo" class="slide" v-bind:class="{ slide: true, square }" :key="photoIndex" />
            </template>

            <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
            <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
        </agile>
    </div>
</template>

<script>

    import { VueAgile } from 'vue-agile'

    export default {
        components: {
            agile: VueAgile
        },
        props: {
            photos: Array,
            square: Boolean
        },
        data: function () {
            return {
                carouselOptions: {
                    infinite: false,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    centerMode: true
                },
            }
        },
        mounted() {
            console.log('mounted carousel');

            setTimeout(() => {
                this.$refs.carousel.reload();
            }, 200)
        }
    }

</script>
