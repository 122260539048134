<template>
    <div id="host-wizard">

        <ul class="steps has-gaps has-content-centered">
            <li class="steps-segment" v-for="step in steps"
                v-bind:class="{ 'is-active': activeStep === step.nr }"
                :data-complete="step.complete">
                <span class="steps-marker" v-on:click="goStep(step.nr)" v-if="step.complete">✔</span>
                <span class="steps-marker" v-else>{{ step.nr }}</span>
                <div class="steps-content"><p class="heading">{{ step.title }}</p></div>
            </li>
        </ul>

        <div class="steps-container">

            <div class="step" v-if="activeStep === 1">
                <div class="field" v-if="!data.name">
                    <label class="label">What is your name?</label>
                    <div class="control">
                        <input type="text" class="input" v-model="data.name" placeholder="Jack O'Neill" />
                    </div>
                </div>

                <div class="field">
                    <label class="label">Title of the activity?</label>
                    <div class="control">
                        <input type="text" class="input" v-model="data.title" placeholder="Learn surfing in Lagos" />
                    </div>
                </div>

                <div class="field">
                    <label class="label">Location</label>
                    <div class="control">
                        <div v-if="(this.draft && this.draft.location) && !changeLocation">
                            <span>{{ `${this.draft.location.locality}, ${this.draft.location.admin_level_1} - ${this.draft.location.country_name}` }}</span>
                            <span class="tag is-link has-cursor-pointer" @click="changeLocationClicked">Change</span>
                        </div>
                        <vue-google-autocomplete
                                v-else
                                id="map"
                                classname="input"
                                placeholder="Enter city or village"
                                types="(cities)"
                                v-bind:enable-geolocation="true"
                                v-on:placechanged="setLocationData">
                        </vue-google-autocomplete>
                    </div>
                    <p class="help">Which city or village is closest where you'll host your surf related activity?</p>
                </div>

                <div class="field">
                    <label class="label">Choose your primary language</label>
                    <div class="control">
                        <div class="select">
                            <select v-model="data.language">
                                <option value="unknown" disabled selected>Select a language</option>
                                <option :value="key" v-for="(value, key) in languages">{{ value }}</option>
                            </select>
                        </div>
                    </div>
                    <p class="help">Your primary language will be the main language set on your activity page. Guests will be able to filter to find activities hosted in your language.</p>
                </div>

                <div class="field">
                    <label class="label">What type of surf course will you host?</label>
                    <div class="control">
                        <div class="select">
                            <select v-model="data.course_type">
                                <option value="0" disabled selected>Select the level</option>
                                <option value="1">Beginners</option>
                                <option value="2">Intermediate</option>
                                <option value="3">Advanced</option>
                            </select>
                        </div>
                    </div>
                    <p class="help">Choose the category that best describes your course.</p>
                </div>

                <div class="field">
                    <div class="control">
                        <button class="button is-primary is-medium is-pulled-right" v-on:click="nextStep" :disabled="!stepIsComplete">Next</button>
                    </div>
                </div>
            </div>

            <div class="step" v-if="activeStep === 2">
                <b-field label="Activity total duration in hours">
                    <b-numberinput min="0" max="24" step="0.5" v-model="data.duration" controls-rounded>
                    </b-numberinput>
                </b-field>
                <div class="field">
                    <label class="label">What we'll do?</label>
                    <div class="control">
                        <textarea class="textarea" placeholder="Write about each activity in the order you'll do them." v-model="data.description"></textarea>
                    </div>
                    <p class="help">Inspire your potential guests, your activity description plays a big role. Write about the details of the itinerary you have planned for them.</p>
                </div>
                <div class="field">
                    <label class="label">What else your guests should know?</label>
                    <div class="control">
                        <textarea class="textarea" placeholder="Add some more info." v-model="data.more_info"></textarea>
                    </div>
                    <p class="help">Give some tips for your guests or write down other requirements.</p>
                </div>
                <b-field label="Attendant should bring" class="is-clearfix">
                    <b-taginput
                        maxtags="10"
                        placeholder="What attendant should bring with them (water, tower etc?)"
                        v-model="data.user_brings">
                    </b-taginput>
                </b-field>
                <div class="field">
                    <div class="control">
                        <button class="button is-primary is-medium is-pulled-right" v-on:click="nextStep" :disabled="!stepIsComplete">Next</button>
                    </div>
                </div>
            </div>

            <div class="step" v-if="activeStep === 3">
                <div class="content">
                    <h4 class="title">Let’s start with your background</h4>
                    <p class="subtitle">Tell us about the expertise and access you’ll provide on the experience.</p>
                </div>
                <div class="field">
                    <label class="label">How many years have you surfed?</label>
                    <div class="control">
                        <label class="radio"><input type="radio" name="surfing-years" value="1-3" v-model="data.about.surfing_years"> 1 - 3</label>
                        <label class="radio"><input type="radio" name="surfing-years" value="4-9" v-model="data.about.surfing_years"> 4 - 9</label>
                        <label class="radio"><input type="radio" name="surfing-years" value="10+" v-model="data.about.surfing_years"> 10+</label>
                    </div>
                </div>
                <div class="field">
                    <label class="label">What took you to surfing?</label>
                    <div class="control">
                        <textarea class="textarea" placeholder="Shortly about your surfing background" v-model="data.about.intro_text"></textarea>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Do you have formal certificates or awards relevant to surfing or coaching?</label>
                    <div class="control">
                        <label class="radio"><input type="radio" name="certificates" v-bind:value="true" v-model="data.about.certificates"> Yes, I do</label>
                        <label class="radio"><input type="radio" name="certificates" v-bind:value="false" v-model="data.about.certificates"> No, I don't</label>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <button class="button is-primary is-medium is-pulled-right" v-on:click="nextStep" :disabled="!stepIsComplete">Next</button>
                    </div>
                </div>
            </div>

            <div class="step" v-if="activeStep === 4">
                <div class="content">
                    <h4 class="title">Illustrative content</h4>
                    <p class="subtitle">Pictures tell more than words. Pick them wisely.</p>
                </div>

                <div class="field">
                    <label class="label">Add 1+ images to describe your service (you must have rights to use them)</label>
                    <div class="control">
                        <div class="images" ref="imageInputs">
                            <div class="image" v-for="photo in draftPhotos"
                                 v-bind:style="{ backgroundImage: 'url(' + photo.url + ')' }"></div>
                            <image-input
                                    v-for="index in imageInputCount"
                                    v-bind:initialFile="images[index - 1]"
                                    :key="index - 1"
                                    @add="addImage" @remove="removeImage" @update="updateImage"
                            />
                        </div>
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <button class="button is-primary is-medium is-pulled-right" v-on:click="nextStep" :disabled="!stepIsComplete">Next</button>
                    </div>
                </div>
            </div>

            <div class="step" v-if="activeStep === 5">
                <div class="field" v-if="!data.tos">
                    <div class="control">
                        <label class="checkbox">
                            <input type="checkbox" v-model="data.tos">
                            I agree to the <a href="/tos" target="_blank">terms and conditions</a>
                        </label>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <button class="button is-primary" v-on:click="saveActivity" :disabled="!stepIsComplete">Save your activity</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    import { DirectUpload } from "@rails/activestorage";
    import VueGoogleAutocomplete from 'vue-google-autocomplete';
    import moment from 'moment';
    import { pickBy } from 'lodash';
    import ax from '../javascripts/axios';
    import ImageInput from './image-input';

    export default {
        components: { VueGoogleAutocomplete, ImageInput, ax, DirectUpload, pickBy, moment },

        props: ['draft', 'location', 'languages', 'user_name', 'photos', 'tos'],

        data: function () {
            return {
                message: "Become a host, start filling!",
                steps: [
                    { nr: 1, title: 'Primary details', complete: false, isActive: true },
                    { nr: 2, title: 'More info', complete: false },
                    { nr: 3, title: 'About you', complete: false },
                    { nr: 4, title: 'Illustrative', complete: false },
                    { nr: 5, title: 'Finish', complete: false }
                ],
                activeStep: 1,
                images: [],
                draftPhotos: this.photos && this.photos.length > 0 ? this.photos : [],
                imageInputCount: 1,
                changeLocation: false,
                data: {
                    id: this.draft && this.draft.id || null,
                    title: this.draft && this.draft.title || null,
                    name: this.user_name,
                    location: {
                        locality: null,
                        country_name: null,
                        country_code: null,
                        admin_level_1: null,
                        coordinates: null
                    },
                    language: this.draft && this.draft.language || 'unknown',
                    course_type: this.draft && this.draft.course_type ? this.getCourseTypeByNumber(this.draft.course_type) : 0,
                    duration: this.draft && this.draft.duration ? moment(this.draft.duration, "HH:mm").hours() : 0,
                    about: {
                        intro_text: this.draft && this.draft.about.intro_text || null,
                        surfing_years: this.draft && this.draft.about.surfing_years || null,
                        certificates: this.draft && this.draft.about.certificates || false
                    },
                    description: this.draft && this.draft.description || null,
                    more_info: this.draft && this.draft.more_info || null,
                    user_brings: this.draft && this.draft.user_brings || [],
                    photos: [],
                    tos: this.tos || false
                }
            }
        },

        methods: {
            checkStepsComplete () {
                this.steps.forEach((step, index) => {
                    switch(index + 1) {
                        case 1:
                            step.complete = (this.data.language !== 'unknown' && this.data.course_type !== 0);
                            break;
                        case 2:
                            step.complete = !!(this.data.user_brings.length > 0 && this.data.duration > 0 && this.data.description && this.data.more_info);
                            break;
                        case 3:
                            step.complete = !!(this.data.about.intro_text && this.data.about.surfing_years);
                            break;
                        case 4:
                            step.complete = (this.data.photos.length > 0);
                            break;
                        case 5:
                            step.complete = !!(this.data.tos);
                            break;
                    }
                });
            },
            goStep (nr) {
                if (this.steps && this.steps[nr - 1] && this.steps[nr - 1].complete) {
                    this.activeStep = nr;
                }
            },
            async nextStep() {
                const response = await this.saveActivity();
                console.log('nextStep response', response);
                if (response) {
                    this.activeStep++;
                }
            },
            prevStep () {
                this.activeStep--;
            },
            setLocationData (locationData, placeResultData) {
                const { locality, country: country_name, administrative_area_level_1: admin_level_1, latitude, longitude } = locationData;
                const { address_components } = placeResultData;
                const { short_name: country_code } = address_components.filter(address_component => address_component.types.includes("country"))[0];

                this.data.location = { locality, country_name, country_code, admin_level_1, coordinates: { latitude, longitude } }
            },
            saveActivity () {
                this.data.course_type = parseInt(this.data.course_type);
                this.data.step = this.activeStep;

                const activityData = pickBy(this.data); // removes empty values

                if (this.data.id) { // update draft
                    console.log('host-wizard called update', activityData);
                    return ax.put(`/activities/${this.draft.id}.json`, {
                        activity: activityData
                    }).then((response) => {
                        console.log('update response', response);
                        const { data: { url, status } } = response;
                        if (url && status === 'pending') {
                            window.location.href = url;
                        }
                        return true;
                    }).catch((error) => {
                        console.log('update error', error);
                        return false;
                    })
                } else { // create new activity
                    console.log('host-wizard called create', activityData);
                    return ax.post('/activities.json', {
                        activity: activityData
                    }).then((response) => {
                        console.log('create response', response);
                        const { data: { id } } = response;
                        this.data.id = id;
                        return true;
                    }).catch((error) => {
                        console.log('create error', error);
                        return false;
                    })
                }

            },
            addImage (file) {
                this.images.push(file);
                this.uploadFile(file);
                this.addAnotherImageInput();
            },
            removeImage (file) {
                const fileIndex = this.images.findIndex(f => f.name === file.name);

                if (fileIndex > -1) {
                    this.images.splice(fileIndex, 1);
                }

                if (this.imageInputCount > 1) {
                    this.imageInputCount = this.imageInputCount - 1;
                    this.addAnotherImageInput();
                }
            },
            updateImage ({ oldFile, newFile }) {
                console.log('oldFile', oldFile);
                console.log('newFile', newFile);
                const oldFileIndex = this.images.findIndex(f => f.name === oldFile.name);

                if (oldFileIndex > -1) {
                    this.images.splice(oldFileIndex, 1);
                }
                this.images.push(newFile);
            },
            addAnotherImageInput () {
                if (this.imageInputCount < 4) this.imageInputCount++
            },
            uploadFile (file) {
                const url = '/rails/active_storage/direct_uploads';
                const upload = new DirectUpload(file, url, this);

                upload.create((error, blob) => {
                    if (error) {
                        // Handle the error
                    } else {
                        this.data.photos.push(blob.signed_id);
                    }
                });
            },
            getCourseTypeByNumber (course_type) {
                const courseTypes = { 'beginners': 1, 'intermediate': 2, 'advanced': 3 };
                return courseTypes[course_type];
            },
            changeLocationClicked () {
                this.changeLocation = true;
            },
            setActiveStep () {
                const { nr } = this.steps.find( ({ complete }) => complete === false );
                this.activeStep = (nr) ? nr : 1;
            },
        },

        watch: {
            data: {
                handler: function () {
                    this.checkStepsComplete();
                },
                deep: true
            },
        },

        mounted: function () {
            this.$nextTick(function () {
                this.checkStepsComplete();
                this.setActiveStep();
            })
        },

        computed: {
            stepIsComplete: function() {
                return this.steps && this.steps[this.activeStep - 1] && this.steps[this.activeStep - 1].complete;
            },
        }
    }

</script>
