<template>
    <div class="book-it">
        <h4 class="title is-4">Book this activity 👇</h4>
        <b-datepicker v-model="date"
                      :first-day-of-week="1"
                      :min-date="minDate"
                      :max-date="maxDate"
                      :events="events"
                      :indicators="indicators"
                      inline>
        </b-datepicker>
        <div class="actions">
            <template v-if="logged_in">
                <b-notification v-if="message.visible" :type="message.type" aria-close-label="Close notification" :message="message.text" />
                <b-button
                        v-else
                        type="is-primary"
                        size="is-medium"
                        class="is-fullwidth"
                        icon-left="calendar-check"
                        :loading="isLoading"
                        :disabled="!date"
                        @click="bookIt">Send booking request
                </b-button>
            </template>
            <template v-else>
                <a class="button is-primary is-medium is-fullwidth" href="/login">Login to book this</a>
            </template>
        </div>
    </div>
</template>

<script>
    import ax from '../javascripts/axios';

    export default {
        components: { ax },
        props: {
            initial_data: Object,
            activity_id: Number,
            logged_in: Boolean
        },
        data: function () {
            const today = new Date();
            const thisMonth = today.getMonth();
            const nextMonth = today.getMonth() + 1;

            return {
                date: null,
                booking: {
                    activity_id: this.activity_id,
                    start_date: null,
                    end_date: null
                },
                isLoading: false,
                message: { type: 'is-success', text: null, visible: false },
                mounted: false,
                minDate: today,
                maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 60),
                events: [
                    new Date(2019, nextMonth, 2),
                    new Date(2019, nextMonth, 6),
                    {
                        date: new Date(2019, nextMonth, 6),
                        type: 'is-info'
                    },
                    {
                        date: new Date(2019, nextMonth, 8),
                        type: 'is-danger'
                    },
                    {
                        date: new Date(2019, nextMonth, 10),
                        type: 'is-success'
                    },
                    {
                        date: new Date(2019, nextMonth, 10),
                        type: 'is-link'
                    },
                    new Date(2019, thisMonth, 12),
                    {
                        date: new Date(2019, nextMonth, 12),
                        type: 'is-warning'
                    },
                    {
                        date: new Date(2019, nextMonth, 16),
                        type: 'is-danger'
                    },
                    new Date(2019, thisMonth, 20),
                    {
                        date: new Date(2019, nextMonth, 29),
                        type: 'is-success'
                    },
                    {
                        date: new Date(2019, nextMonth, 29),
                        type: 'is-warning'
                    },
                    {
                        date: new Date(2019, nextMonth, 29),
                        type: 'is-info'
                    }
                ],
                bars: true
            }
        },
        computed: {
            indicators() {
                return this.bars ? 'bars' : 'dots'
            }
        },
        methods: {
            bookIt() {
                console.log('book it', this.booking);
                this.isLoading = true;
                ax.post('/bookings.json', {
                    booking: this.booking
                }).then((response) => {
                    this.isLoading = false;
                    this.message.type = 'is-success';
                    this.message.text = 'Booking request was successfully sent! Follow it up on the booking page (booking status, conversation with the host, etc)';
                    this.message.visible = true;
                    console.log(response);
                }).catch((error) => {
                    this.isLoading = false;
                    this.message.type = 'is-danger';
                    this.message.text = 'Something went wrong 🐒';
                    this.message.visible = true;
                    console.log(error);
                })
            },
            markDates() {
                console.log('marking dates with initial data', this.initial_data);
            }
        },
        watch: {
            date: function (val) {
                this.booking.start_date = val;
                this.booking.end_date = val;
            }
        },
        mounted() {
            console.log('mounted book-it');
        },
        created() {
            this.markDates()
        }
    }

</script>
