<template>
    <div id="activity-edit">
        <h1 class="title">{{ data.primary.title }}</h1>
        <h2 class="subtitle"><span class="tag">{{ this.getCourseTypeByNumber(data.primary.course_type) }}</span></h2>
        <b-tabs v-model="activeTab" v-bind:animated="false">

            <b-tab-item label="Primary details">
                <div class="field">
                    <label class="label">Title of the activity?</label>
                    <div class="control">
                        <input type="text" class="input" v-model="data.primary.title" placeholder="Learn surfing in Lagos" />
                    </div>
                </div>

                <div class="field">
                    <label class="label">Location</label>
                    <div class="control">
                        <div v-if="(this.activity && this.activity.location) && !changeLocation">
                            <span>{{ `${this.activity.location.locality}, ${this.activity.location.admin_level_1} - ${this.activity.location.country_name}` }}</span>
                            <span class="tag is-link has-cursor-pointer" @click="changeLocationClicked">Change</span>
                        </div>
                        <vue-google-autocomplete
                                v-else
                                id="map"
                                classname="input"
                                placeholder="Enter city or village"
                                types="(cities)"
                                v-bind:enable-geolocation="true"
                                v-on:placechanged="setLocationData">
                        </vue-google-autocomplete>
                    </div>
                    <p class="help">Which city or village is closest where you'll host your surf related activity?</p>
                </div>

                <div class="field">
                    <label class="label">Choose your primary language</label>
                    <div class="control">
                        <div class="select">
                            <select v-model="data.primary.language">
                                <option value="unknown" disabled selected>Select a language</option>
                                <option :value="key" v-for="(value, key) in languages">{{ value }}</option>
                            </select>
                        </div>
                    </div>
                    <p class="help">Your primary language will be the main language set on your activity page. Guests will be able to filter to find activities hosted in your language.</p>
                </div>

                <div class="field">
                    <label class="label">What type of surf course will you host?</label>
                    <div class="control">
                        <div class="select">
                            <select v-model="data.primary.course_type">
                                <option value="0" disabled selected>Select the level</option>
                                <option value="1">Beginners</option>
                                <option value="2">Intermediate</option>
                                <option value="3">Advanced</option>
                            </select>
                        </div>
                    </div>
                    <p class="help">Choose the category that best describes your course.</p>
                </div>

                <div class="field">
                    <div class="control">
                        <button class="button is-primary is-medium is-pulled-right" data-tab='primary' :disabled="!validateData" v-on:click="save">Save</button>
                    </div>
                </div>
            </b-tab-item>

            <b-tab-item label="More information">
                <b-field label="Activity total duration in hours">
                    <b-numberinput min="0" max="24" step="0.5" v-model="data.duration" controls-rounded>
                    </b-numberinput>
                </b-field>
                <div class="field">
                    <label class="label">What we'll do?</label>
                    <div class="control">
                        <textarea class="textarea" placeholder="Write about each activity in the order you'll do them." v-model="data.description"></textarea>
                    </div>
                    <p class="help">Inspire your potential guests, your activity description plays a big role. Write about the details of the itinerary you have planned for them.</p>
                </div>
                <div class="field">
                    <label class="label">What else your guests should know?</label>
                    <div class="control">
                        <textarea class="textarea" placeholder="Add some more info." v-model="data.more_info"></textarea>
                    </div>
                    <p class="help">Give some tips for your guests or write down other requirements.</p>
                </div>
                <b-field label="Attendant should bring" class="is-clearfix">
                    <b-taginput
                            maxtags="10"
                            placeholder="What attendant should bring with them (water, tower etc?)"
                            v-model="data.user_brings">
                    </b-taginput>
                </b-field>
                <div class="field">
                    <div class="control">
                        <button class="button is-primary is-medium is-pulled-right" v-on:click="save" :disabled="!validateData">Save</button>
                    </div>
                </div>
            </b-tab-item>

            <b-tab-item label="About you">
                <div class="content">
                    <h4 class="title">Let’s start with your background</h4>
                    <p class="subtitle">Tell us about the expertise and access you’ll provide on the experience.</p>
                </div>
                <div class="field">
                    <label class="label">How many years have you surfed?</label>
                    <div class="control">
                        <label class="radio"><input type="radio" name="surfing-years" value="1-3" v-model="data.about.surfing_years"> 1 - 3</label>
                        <label class="radio"><input type="radio" name="surfing-years" value="4-9" v-model="data.about.surfing_years"> 4 - 9</label>
                        <label class="radio"><input type="radio" name="surfing-years" value="10+" v-model="data.about.surfing_years"> 10+</label>
                    </div>
                </div>
                <div class="field">
                    <label class="label">What took you to surfing?</label>
                    <div class="control">
                        <textarea class="textarea" placeholder="Shortly about your surfing background" v-model="data.about.intro_text"></textarea>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Do you have formal certificates or awards relevant to surfing or coaching?</label>
                    <div class="control">
                        <label class="radio"><input type="radio" name="certificates" v-bind:value="true" v-model="data.about.certificates"> Yes, I do</label>
                        <label class="radio"><input type="radio" name="certificates" v-bind:value="false" v-model="data.about.certificates"> No, I don't</label>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <button class="button is-primary is-medium is-pulled-right" v-on:click="save" :disabled="!validateData">Save</button>
                    </div>
                </div>
            </b-tab-item>

            <b-tab-item label="Photos">
                <div class="content">
                    <h4 class="title">Illustrative content</h4>
                    <p class="subtitle">Pictures tell more than words. Pick them wisely.</p>
                </div>

                <div class="field">
                    <label class="label">Add 1+ images to describe your service (you must have rights to use them)</label>
                    <div class="control">
                        <div class="images" ref="imageInputs">
                            <div class="image" v-for="photo in activityPhotos"
                                 v-bind:style="{ backgroundImage: 'url(' + photo.url + ')' }"></div>
                            <image-input
                                    v-for="index in imageInputCount"
                                    v-bind:initialFile="images[index - 1]"
                                    :key="index - 1"
                                    @add="addImage" @remove="removeImage" @update="updateImage"
                            />
                        </div>
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <button class="button is-primary is-medium is-pulled-right" v-on:click="save" :disabled="!validateData">Save</button>
                    </div>
                </div>
            </b-tab-item>
        </b-tabs>

    </div>
</template>

<script>

    import { DirectUpload } from "@rails/activestorage";
    import VueGoogleAutocomplete from 'vue-google-autocomplete';
    import moment from 'moment';
    import { pickBy } from 'lodash';
    import ax from '../javascripts/axios';
    import ImageInput from './image-input';

    export default {
        components: { VueGoogleAutocomplete, ImageInput, ax, DirectUpload, pickBy, moment },

        props: ['activity', 'location', 'languages', 'user_name', 'photos', 'tos'],

        data: function () {
            return {
                activeTab: 0,
                images: [],
                activityPhotos: this.photos && this.photos.length > 0 ? this.photos : [],
                imageInputCount: 1,
                changeLocation: false,
                data: {
                    id: this.activity && this.activity.id || null,
                    primary : {
                        title: this.activity && this.activity.title || null,
                        location: {
                            locality: null,
                            country_name: null,
                            country_code: null,
                            admin_level_1: null,
                            coordinates: null
                        },
                        language: this.activity && this.activity.language || 'unknown',
                        course_type: this.activity && this.activity.course_type ? this.getCourseTypeByNumber(this.activity.course_type) : 0
                    },
                    info : {
                        duration: this.activity && this.activity.duration ? moment(this.activity.duration, "HH:mm").hours() : 0,
                        description: this.activity && this.activity.description || null,
                        more_info: this.activity && this.activity.more_info || null,
                        user_brings: this.activity && this.activity.user_brings || []
                    },
                    about: {
                        intro_text: this.activity && this.activity.about.intro_text || null,
                        surfing_years: this.activity && this.activity.about.surfing_years || null,
                        certificates: this.activity && this.activity.about.certificates || false
                    },
                    photos: []
                }
            }
        },

        methods: {
            validateData (event, tab = null) {
                if (!tab) {
                    tab = event.target.dataset.tab;
                    console.log('tab', tab);
                }
                switch(tab) {
                    case 'primary':
                        const { language, course_type } = this.data.primary;
                        return (language !== 'unknown' && course_type !== 0);
                    case 'info':
                        const { user_brings, duration, description, more_info } = this.data.info;
                        return !!(user_brings.length > 0 && duration > 0 && description && more_info);
                    case 'about':
                        const { intro_text, surfing_years } = this.data.about;
                        return !!(intro_text && surfing_years);
                    case 'photos':
                        return (this.data.photos.length > 0);
                }
            },
            goStep (nr) {
                if (this.steps && this.steps[nr - 1] && this.steps[nr - 1].complete) {
                    this.activeStep = nr;
                }
            },
            async save(tab) {
                const isValid = this.validateData(tab);
                if (isValid) {
                    const response = await this.saveActivity();
                    console.log('SaveStep response', response);
                    if (response) {
                        this.activeStep++;
                    }
                }
            },
            prevStep () {
                this.activeStep--;
            },
            setLocationData (locationData, placeResultData) {
                const { locality, country: country_name, administrative_area_level_1: admin_level_1, latitude, longitude } = locationData;
                const { address_components } = placeResultData;
                const { short_name: country_code } = address_components.filter(address_component => address_component.types.includes("country"))[0];

                this.data.location = { locality, country_name, country_code, admin_level_1, coordinates: { latitude, longitude } }
            },
            saveActivity () {
                this.data.course_type = parseInt(this.data.course_type);

                const activityData = pickBy(this.data); // removes empty values

                if (this.data.id) { // update activity
                    console.log('activity-edit called update', activityData);
                    return ax.put(`/activities/${this.activity.id}.json`, {
                        activity: activityData
                    }).then((response) => {
                        console.log('update response', response);
                        const { data: { url, status } } = response;
                        if (url && status === 'pending') {
                            window.location.href = url;
                        }
                        return true;
                    }).catch((error) => {
                        console.log('update error', error);
                        return false;
                    })
                }

            },
            addImage (file) {
                this.images.push(file);
                this.uploadFile(file);
                this.addAnotherImageInput();
            },
            removeImage (file) {
                const fileIndex = this.images.findIndex(f => f.name === file.name);

                if (fileIndex > -1) {
                    this.images.splice(fileIndex, 1);
                }

                if (this.imageInputCount > 1) {
                    this.imageInputCount = this.imageInputCount - 1;
                    this.addAnotherImageInput();
                }
            },
            updateImage ({ oldFile, newFile }) {
                const oldFileIndex = this.images.findIndex(f => f.name === oldFile.name);

                if (oldFileIndex > -1) {
                    this.images.splice(oldFileIndex, 1);
                }
                this.images.push(newFile);
            },
            addAnotherImageInput () {
                if (this.imageInputCount < 4) this.imageInputCount++
            },
            uploadFile (file) {
                const url = '/rails/active_storage/direct_uploads';
                const upload = new DirectUpload(file, url, this);

                upload.create((error, blob) => {
                    if (error) {
                        // Handle the error
                    } else {
                        this.data.photos.push(blob.signed_id);
                    }
                });
            },
            getCourseTypeByNumber (course_type) {
                const courseTypes = { 'beginners': 1, 'intermediate': 2, 'advanced': 3 };
                return courseTypes[course_type];
            },
            changeLocationClicked () {
                this.changeLocation = true;
            },
        },

        computed: {
            isTabValid: function() {
                return this.steps && this.steps[this.activeStep - 1] && this.steps[this.activeStep - 1].complete;
            },
        }
    }

</script>
