<template>
    <div class="image-input"
        v-bind:style= "[imageData ? { 'background-image': `url(${imageData})` } : {}]"
        v-bind:class="{ hasImage: imageData }"
        @click="chooseImage" @dragover.prevent @drop.stop.prevent="dropImage">
        <vue-fontawesome icon="minus-circle" v-on:click.stop="remove" size="lg" title="Remove this image" />
        <span v-if="!imageData" class="placeholder">choose or drag a photo</span>
        <input
            class="file-input"
            ref="fileInput"
            type="file"
            @input="onSelectFile">
    </div>
</template>

<script>
    export default {
        props: ['initialFile'],
        data () {
            return {
                file: this.initialFile,
                imageData: null
            }
        },
        methods: {
            chooseImage () {
                this.$refs.fileInput.click()
            },
            dropImage (e) {
                const files = e.dataTransfer.files;
                if (files.length > 0) {
                    this.createFile(files[0]);
                }
            },
            onSelectFile () {
                const input = this.$refs.fileInput;
                const files = input.files;
                if (files && files[0]) {
                    this.createFile(files[0])
                }
            },
            createFile (_file) {
                if (!_file.type.match('image.*')) {
                    alert('Select an image please');
                    return;
                }

                this.readFile(_file);

                if (this.file) {
                    this.updateImage(this.file, _file)
                } else {
                    this.addImage(_file);
                }
                this.file = _file;
            },
            readFile (_file) {
                const reader = new FileReader();
                reader.onload = e => {
                    this.imageData = e.target.result
                };
                reader.readAsDataURL(_file);
            },
            remove () {
                this.$emit('remove', this.file);
                this.imageData = null;
                this.file = null;
            },
            addImage (file) {
                this.$emit('add', file);
            },
            updateImage (oldFile, newFile) {
                this.$emit('update', { oldFile, newFile });
            }
        },
        mounted () {
            this.$nextTick(() => {
                if (this.file) {
                    this.readFile(this.file);
                }
            });
        }
    }
</script>