import axios from 'axios/index'

const token = document.querySelector('[name="csrf-token"]') || { content: 'no-csrf-token' };
const ax = axios.create({
    headers: {
        common: {
            'X-CSRF-Token': token.content
        }
    }
});

export default ax;